
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


export function Contact() {
    return (
    <div>
        <Grid container justify="center">
            <h1 className="page-title">Contact</h1>
        </Grid>
       
       <Grid container justify="center">
        <iframe height = {500} id="JotFormIFrame-200310735513138" title="General Inquiry Contact Form"  
         src="https://form.jotform.com/200310735513138" 
         scrolling="no" > </iframe> 
        </Grid>
    </div>
        
    
    );
}