import React, {useState, useCallback} from 'react';
import { BrowserRouter as Router ,Link,Route,Switch} from 'react-router-dom';

import PropTypes from 'prop-types';
import { WithStyles, createStyles } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Theme from "@material-ui/styles/ThemeProvider"
import Typography from '@material-ui/core/Typography';
import { photos } from "./photos";

import Gallery from "react-photo-gallery";
import FsLightbox from 'fslightbox-react';


//full screen or orig image
//html in description
//contact page,
//artist statement page
//navigation
//logo
//styles
//footer
//general layout
//source images



function Home() {

  const [toggler, setToggler] = useState(false); 
  const [currentSlide, setCurrentSlide] = useState(1);
  

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  return (

  
    <div className="App">
      

    
  
    
    <div className="{classes.root}">
    <Grid container justify="center">
            <h1 className="page-title">Mixed Media</h1>
        </Grid>
      <Grid container justify = "center">
        <Grid item xs={12} sm={10} md={7}>
          <Paper className="{classes.paper}">
          
            <Gallery photos={photos} onClick={(event, { photo, index }) => {
                    setCurrentSlide(index+1);
                    setToggler(!toggler);
                    }} />
             <FsLightbox
                toggler={toggler}
                sources = {photos.map(x => x.source.regular)}
                slide={currentSlide}
                captions={[
                    <>
                        <h2>A Pill at a Time</h2>
                        <div>8.5" x 11"</div>
                        <div>Watercolor, pen, and hints of silver leaf work together to create A chaotic but emotional piece about the effects of taking mental illness medication. The scrawled writing and serotonin molecule are two focal points that give this work character about what it means to have low serotonin levels and lingering inner thoughts. As someone who takes medication, I understand the process of finding the correct medication for your body and the impatience of hoping medication will make you better.</div>
                    </>,
                    <>
                        <h2>Only Fools Are Satisfied</h2>
                        <div>13.5" x 11.75"</div>
                        <div>A layered collage using acrylic paint and tape on cardboard spells out lyrics to the song Vienna, by Billy Joel. Billy Joel is one of my favorite artists who has taught me a lot about myself and managed to help me through the darkest of times. I wanted to pay ode to my favorite song of his and incorporate Mark Bradford’s artistic style as inspiration to this piece.</div>
                    </>,
                    <>
                        <h2>Just Me</h2>
                        <div>10.5" x 13"</div>
                        <div>WA playful and lighthearted piece using magazine cutouts and acrylic paint were layered to display a story of my personal life. My love for music, my boyfriend, art, and the city I live in inspired me to create a composition that reminds me to be grateful and to look forward to what life holds.</div>
                    </>,
                    <>
                        <h2>Stop and Smell the Roses</h2>
                        <div>14" x 20"</div>
                        <div>A colored pencil self portrait depicting the love I am feeling in my current relationship, hence red as the dominant color. I wanted to make a piece that was joyful amidst the hardship of the coronavirus pandemic and was an escape from reality.</div>
                    </>,
                    <>
                        <h2>Rollercoaster</h2>
                        <div>20" x 16.5"</div>
                     <div>Colored pencil and pen were used to create this piece to show the lows of depression and highs of mania as a result of bipolar disorder. Two faces collide into one indicating the contrasting feelings of this mental illness. I focused on color theory by using complementary hues of blue and orange. Synthesizing this piece, the background contains a neurographic to represent this illness being a disorder that affects the brain.</div>
                    </>,
                     <>
                        <h2>The Puppeteer</h2>
                        <div>11" x 13.5"</div>
                        <div>Anxiety is a disorder that consumes a third of the population. It is a controlling, manipulative, and exhausting illness to have. I had to capture the dark symptoms of anxiety and what it feels like to have no control over your body or fate. Anxiety can often feel like bad luck and “what if’s?..” prompting me to include focal points that represent bad omens. Graphite pencil and acrylic paint elevates this overall composition.</div>
                    </>

            ]} 
            /> 
          </Paper>
        </Grid>
      </Grid>
    </div>
    <div>
   
    
    </div> 
    </div>
   
  );
}

export default Home;
