import React, {useState, useCallback} from 'react';

import './App.css';
import { BrowserRouter as Router ,Link,Route,Switch} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Theme from "@material-ui/styles/ThemeProvider"
import Typography from '@material-ui/core/Typography';
import  Home  from './Home';
import { Statement } from './Statement';
import { Contact } from './Contact';


//full screen or orig image
//html in description
//contact page,
//artist statement page
//navigation
//styles
//footer
//general layout
//source images



function App() {

  return (
    <main>
{/* 
      <div
    style={{
        position: 'absolute', 
        left: '50%', 
        //top: '50%',
        transform: 'translate(-50%, -0%)'
    }}
>
<a href="/">  <img src ="sklogo.png" width={300}></img> </a>
</div>
*/}

{/* 
xm
sm
md
lg
xl

*/}
      <Grid container >
        <Grid item xs={1}sm={4} >
        </Grid>
        <Grid item xs={10} sm={4} >
          <Grid container justify="center">
          <a href="/">  <img src ="sklogo.png" width={300}></img> </a>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Grid container justify="flex-end">
            <Button color="secondary" href="/statement">Artist Statement</Button>
            <Button color="secondary" href="/contact">Contact</Button>
          </Grid>
        </Grid>
      </Grid>
      
      <div className="bktest">
  
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/statement" component={Statement} />
            <Route path="/contact" component={Contact} />
        </Switch>
        </div>
        <div className="footer">&copy;2020-21 Sage Kessler</div>
        
    </main>
  )


}

export default App;
