import Carousel, { Modal, ModalGateway, ViewType } from "react-images";

export const photos = [
    {
      src: "img/a_pill_at_a_time_thumb.jpg",
      width: 545,
      height: 704,
      caption: "<h2>A Pill at a Time</h3><p>Paragraph</p>",
      source: {
        download: "img/a_pill_at_a_time.jpg",
        fullscreen: "img/a_pill_at_a_time_fs.jpg",
        regular: "img/a_pill_at_a_time.jpg",
        thumbnail: "img/a_pill_at_a_time_thumb.jpg"
      }
    },
    {
        src: "img/vienna_thumb.jpg",
        width: 309,
        height: 263,
        caption: "<h2>Vienna</h2>" ,
        source: {
          // download?: string;
           fullscreen: "img/vienna.jpg",
           regular: "img/vienna.jpg"
         //  thumbnail?: string;
         }  
      },
      {
      src: "img/day_in_the_life_thumb.jpg",
      width: 324,
      height: 265,
      caption: "<h2>Day in the Life</h2>",
      source: {
        // download?: string;
         fullscreen: "img/day_in_the_life.jpg",
         regular: "img/day_in_the_life.jpg",
         thumbnail: "img/day_in_the_life_thumb.jpg"
       }  
    },
  
    {
        src: "img/stop_and_smell_the_roses_thumb.jpg",
        width: 334,
        height: 235,
        caption: "<h2>Stop and Smell the Roses</h2>" ,
        source: {
          // download?: string;
           fullscreen: "img/stop_and_smell_the_roses_thumb.jpg",
           regular: "img/stop_and_smell_the_roses.jpg"
         //  thumbnail?: string;
         }  
      },
      {
        src: "img/rollercoaster_thumb.jpg",
        width: 323,
        height: 272,
        caption: "<h2>New Title</h2>" ,
        source: {
          // download?: string;
           fullscreen: "img/new.jpg",
           regular: "img/rollercoaster.jpg"
         //  thumbnail?: string;
         }  
      },
      {
        src: "img/the_puppeteer_thumb.jpg",
        width: 296,
        height: 377,
        caption: "<h2>The Puppeteer</h2>" ,
        sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
        source: {
          // download?: string;
           fullscreen: "img/the_puppeteer.jpg",
           regular: "img/the_puppeteer.jpg"
         //  thumbnail?: string;
         }  
      }
  ];
  