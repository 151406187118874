
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


export function Statement() {
    return (
        <div>
        <Grid container justify="center">
            <h1 className="page-title">Artist Statement</h1>
        </Grid>
        <Grid container justify="center">
            <Grid item sm={9} md={9} xs={12}>
                <Paper className="paper">
                    <h4><em>What it means to be an artist:</em></h4>
                    <p>To me, being an artist means expressing yourself through mediums such as paint, pencils, and markers, to create a valuable piece that inspires others. An artist can see the beauty and possibility in anything around them and uses that to execute artwork. Anyone can be an artist no matter their skill set because art is all around us and connects to everyone. Artists take what they are feeling inside and transform it into a physical piece. The beauty in being an artist is that it does not discriminate by race, gender, or financial status. You just need to have a story to tell and the motivation to express yourself. A true artist will create work that has an impact on them or other people in the world by using their skillset to spark conversation. A true artist is strong and willingly vulnerable, exposing their feelings.</p>
                    <h4><em>Beginnings and turning points:</em></h4>
                    <p>At a young age, I started to do ceramics which helped me to explore my creative side. I soon began to enjoy different forms of art such as decorating cakes, painting, and drawing. An experience I hold dear to my heart is my eighth grade art class, taught by Ms. Rambo. During middle school and up until present day, I’ve suffered from anxiety and other problems with mental illness. Ms.Rambo guided me into transforming my suffering into art. Now I can channel my pain and use art as a coping mechanism. I’ve learned that I can count on art to get me through the roughest points in my life. Taking her class was special to me because our personal connection allowed me to gain confidence in myself. I struggled in eighth grade but she always gave me something to look forward to during the day. Her faith in me allowed me to not only grow as an artist, but as a person in general.I am grateful that I got to learn from someone who was just as inspired and intrigued by art as I was. Her passion and experience in the art world made me excited to become a future artist and led me to the realization that I wanted to do this in the future.</p>
                    <h4><em>The Future:</em></h4>
                    <p>In the future I want to create murals in public areas because I love making big artwork that unfolds into a story. The thought of someone passing by my art on a street and taking the time out of their day to admire it invigorates me for future projects. I would love to paint murals on large buildings or in popular cities, such as Los Angeles, because the element of art in a simple town or city brings interest and individuality to the community. I’m aiming to use the eye-catching factor of big artwork to inspire as many people as possible. My favorite type of art to make are portraits and using the element of bold colors. So far, the biggest piece I’ve painted is a mural, about 6 feet tall, but I hope to delve more into murals for my community and people around me. The light and personality that art brings into my life is something that I want to share with other people so they can experience its significance as well. I want my pieces to spark innovation, change, and creativity for all individuals.</p>
                    
                </Paper>
            </Grid>
        </Grid>
        </div>
    )
}